import React from 'react';
import {Link} from "gatsby";
import Img from 'gatsby-image' ;

const Bio =({ bioText, bioImg, alt, instagram, linkedin, mail}) => (

  <div className='bio'>
    <div className='inner-container'>
      <div className='bio-image'>
        <Img 
          fluid={bioImg} 
          alt={alt} 
          title={alt}
        />
        <div className='bio-social'>
          <ul>
            <li><a href={instagram} target='_blank' rel='noopener noreferrer'>Instagram</a></li>
            <li><a href={linkedin} target='_blank' rel='noopener noreferrer'>LinkedIn</a></li>
          </ul>
        </div>
      </div>
      <div className='bio-description' >
        <div dangerouslySetInnerHTML={{__html : bioText }}></div>
        <a className='button primary' href={ `mailto:${mail}` } target='_blank' rel='noopener noreferrer'>Contactez-moi<span>&#8594;</span></a>
        <Link className='button secondary' to="/a-propos/">En savoir plus<span>&#8594;</span></Link>
      </div>
    </div>
  </div>

)

export default Bio
