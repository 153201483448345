import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import HomeHeader from '../components/home-header';
import Bio from '../components/bio.js';
import NewsletterBlock from '../components/newsletter-block.js';
import FeaturedPostList from '../components/featured-post-list.js';

const Home = ({ data }) => {

  const page = data.wordpressPage ;
  const posts = data.allWordpressPost.edges.slice().reverse() ;

  return (
    <Layout>
      <HomeHeader 
        postTitle={page.acf.head_text}
        backgroundImage={page.acf.head_img.localFile.childImageSharp.fluid } 
        alt={page.acf.head_img.alt_text} 
      />
      <Bio 
        bioText={page.acf.bio_text}
        bioImg={page.acf.bio_img.localFile.childImageSharp.fluid}
        alt={page.acf.bio_img.alt_text}                
        instagram={page.acf.instagram_url} 
        linkedin={page.acf.linkedin_url}
        mail={page.acf.mail_url} 
      /> 
      <FeaturedPostList 
        posts={posts} 
      />
      <NewsletterBlock 
        BannerText={page.acf.newsletter_text}
        backgroundImage={page.acf.newsletter_img.localFile.childImageSharp.fluid}
        alt={page.acf.newsletter_img.alt_text} 
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWordpressPost (limit: 3, filter:{ categories: {elemMatch:{slug:{eq: "favoris"}}} }, sort:{fields: [date]}) {
      edges {
        node {
          id
          title
          content
          date(formatString: "D MMMM YYYY", locale: "fr")
          excerpt
          slug
          acf {
            post_head {
              alt_text
              title
              localFile {
                name
                childImageSharp {
                  fluid (maxWidth: 1200, maxHeight: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                  }
                }
              }
            }
          }
          tags {
            slug
	    id
          }
        }
      }
    }
    wordpressPage (slug: {eq: "home"} ) {
      acf {
        linkedin_url
        instagram_url
        mail_url
        head_text
        bio_text
        newsletter_text
        head_img {
          alt_text
          localFile {
            name
            childImageSharp {
              fluid (maxWidth: 1200){
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        newsletter_img {
          alt_text
          localFile {
            name
            childImageSharp {
              fluid (maxWidth: 1200){
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        bio_img {
          alt_text
          localFile {
            name
            childImageSharp {
              fluid (maxWidth: 600, maxHeight: 800, cropFocus: CENTER){
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default Home ;
