import React from "react";
import posed from 'react-pose';
import {Link} from 'gatsby';
import Img from 'gatsby-image';

const Wrapper = posed.div({
  hoverable: true,
  init: {opacity: 1},
  hover: {opacity: 0, transition: {duration: 500}} 
});
const Items = posed.div({
  init: {opacity: 1, x: 0},
  hover: {opacity: 0, x: 20, transition: {duration: 500}}
});

const FeaturedPostList = ({ posts, sectionTitle }) => {

  return (

    <div className='featured-post-list'>
      <div className='inner-container'>
        {sectionTitle &&
          <div className="section">
            <h2 className='section-title'>{sectionTitle}</h2>
          </div>
        }
        <ul className='post-list-container'>
            {posts.map(({node}, index) => (
              <li className={'post ' + (index === 0 ? 'full' : 'half')} key={node.id}>
                  
                  <Link to={ `travaux/${node.slug}` }>
                    <Img fluid={node.acf.post_head.localFile.childImageSharp.fluid} 
                      className='post-image' 
                      title={node.acf.post_head.title}
                      alt={node.acf.post_head.alt_text}/>
                    <Wrapper className='post-title-wrapper'>
                      <Items className='post-title'>
                        {/* <div className="tags">
                          {node.tags && node.tags.map((tag)=>(
                            <span>{ tag.slug }</span>
                          ))}
                        </div> */}
                        <h3 dangerouslySetInnerHTML={{ __html : node.title}} />
                        <div className='date'>{node.date} </div>                              
                      </Items>
                    </Wrapper>
                  </Link>

              </li>
          ))}                      
        </ul> 
      </div>
    </div>

  )

}

export default FeaturedPostList;
